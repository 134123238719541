body {
	margin: 0;
	padding: 0; }

.container {
	max-width: 1170px;
	padding: 0; }

input, button, input:active, button:active {
	outline: none; }
:focus {
	outline: none; }

ul {
	list-style-type: none;
	padding: 0;
	margin: 0; }

.clear {
	clear: both; }

a:focus {
	outline: none; }


.hidden-item {
	display: none; }

.visible-item {
	display: block; }


h2, h3 {
	margin-top: 0;
	margin-bottom: 0; }

p {
	margin-top: 0;
	margin-bottom: 0; }

.hidden-contant {
	display: none; }

.select2-dropdown {
	border: 1px solid #e1e1e1;
	color: rgba(107,107,107,0.6);
	font-family: Roboto;
	font-size: 14px;
	font-weight: 400;
	line-height: 16px; }
.select2-container--default .select2-search--dropdown .select2-search__field {
	border: 1px solid #e1e1e1; }
.select2-container--default .select2-results__option[aria-selected="true"] {
	background-color: #ae2f3c;
	color: white;
	font-weight: 700; }
.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #b5b5b5;
	color: #0b0b0b; }
.select2-results__option {
	padding-left: 10px;
	padding-top: 10px;
	padding-bottom: 10px; }

header {
	background-color: #e7e7e7;
	max-width: 1170px;
	margin: 0 auto;
	min-height: 128px;
	padding-bottom: 20px;
	.logo {
		display: block;
		padding-top: 26px;
		margin-left: 42px; }
	.menu1 {
		padding-top: 54px;
		margin-left: 66px;
		li {
			display: inline-block;
			vertical-align: top;
			margin-right: 27px;
			&:last-child {
				margin-right: 0; }
			a {
				color: #232323;
				font-family: "Open Sans";
				font-size: 14px;
				font-weight: 400;
				line-height: 24px; } } }
	.contacts {
		margin-left: 77px;
		padding-top: 25px; }
	.contacts-contant {
		display: inline-block;
		vertical-align: top;
		font-family: "Open Sans";
		font-weight: 400;
		line-height: 25px;
		.tel {
			color: #ae2f3c;
			font-size: 24px;
			display: block; }
		.mail {
			color: #232323;
			font-size: 24px;
			display: block;
			padding-top: 5px;
			padding-left: 6px; }
		p {
			color: #232323;
			font-size: 14px;
			padding-top: 5px; } }
	.social-wrap {
		display: inline-block;
		vertical-align: top;
		position: relative;
		padding-left: 20px;
		text-align: center;
		max-width: 196px;
		width: 100%;
		&:before {
			content: '';
			position: absolute;
			top: -5px;
			left: 19px;
			width: 1px;
			height: 94px;
			opacity: 0.5;
			background-color: #232323;
			display: block; }
		p {
			text-align: center;
			color: #232323;
			font-family: "Open Sans";
			font-size: 14px;
			font-weight: 400;
			line-height: 24px;
			padding-top: 5px; }
		.soc-icons {
			padding-top: 5px;
			li {
				display: inline-block;
				margin-right: 4px;
				vertical-align: top;
				&:last-child {
					margin-right: 0; }
				a {
					display: block;
					width: 40px;
					height: 40px;
					border: 1px solid #be926f;
					border-radius: 50%;
					background-repeat: no-repeat;
					background-position: center center;
					-webkit-transition: all .25s;
					-moz-transition: all .25s;
					-ms-transition: all .25s;
					-o-transition: all .25s;
					transition: all .25s;
					&:hover {
						background-color: #be926f;
						&.vk {
							background-image: url(../img/icon-vk-white.png); }
						&.ok {
							background-image: url(../img/icon-ok-white.png); }
						&.fb {
							background-image: url(../img/icon-fb-white.png); } }
					&.vk {
						background-image: url(../img/icon-vk.png); }
					&.ok {
						background-image: url(../img/icon-ok.png); }
					&.fb {
						background-image: url(../img/icon-fb.png); } } } } } }

.nav-menu2 {
	padding-top: 13px;
	padding-bottom: 20px;
	.menu2 {
		text-align: center;
		li {
			display: inline-block;
			vertical-align: top;
			margin-right: 57px;
			color: #232323;
			font-family: "Open Sans";
			font-size: 24px;
			font-weight: 600;
			line-height: 24px;
			-webkit-transition: all .25s;
			-moz-transition: all .25s;
			-ms-transition: all .25s;
			-o-transition: all .25s;
			transition: all .25s;
			position: relative;
			&.active, &:hover, &:active {
				color: #be926f; }

			&:last-child {
				margin-right: 0; }
			&:hover .sub-menu {
				opacity: 1; }
			.sub-menu {
				position: absolute;
				top: 25px;
				left: 0;
				width: 623px;
				min-height: 75px;
				background-color: #f3f3f3;
				border: 1px solid #be926f;
				z-index: 200;
				text-align: left;
				padding: 10px;
				padding-top: 0;
				opacity: 0;
				li {
					display: inline-block;
					margin-right: 15px;
					line-height: 1;
					vertical-align: top;
					a {
						color: #232323;
						font-family: "Open Sans";
						font-size: 14px;
						font-weight: 400;
						line-height: 1;
						&:hover {
							text-decoration: underline; } } } }
			a {
				color: #232323;
				font-family: "Open Sans";
				font-size: 24px;
				font-weight: 600;
				line-height: 24px;
				-webkit-transition: all .25s;
				-moz-transition: all .25s;
				-ms-transition: all .25s;
				-o-transition: all .25s;
				transition: all .25s;
				&.active, &:hover, &:active {
					color: #be926f; } } } } }

.bx-wrapper {
	margin-bottom: 0; }
.bx-wrapper .bx-viewport {
	border: 0;
	left: 0;
	box-shadow: none; }

.slider {
	.slider-wrap {
		position: relative;
		.pager {
			position: absolute;
			right: 0;
			top: 0;
			a {
				display: block;
				color: #232323;
				font-family: "Open Sans";
				font-size: 17px;
				font-weight: 600;
				padding-left: 40px;
				width: 370px;
				border-bottom: 1px solid rgba(0,0,0,0.15);
				&:last-child {
					border-bottom: 0; }
				span {
					display: inline-flex;
					align-items: center; /* cross axis */;
					line-height: 1; /* reset */;
					min-height: 119px; }
				&:nth-child(2) {
					&.active {
						&:after {
							content: '02'; } } }
				&:nth-child(3) {
					&.active {
						&:after {
							content: '03'; } } }
				&.active {
					background-color: #be926f;
					position: relative;
					color: white;
					line-height: 20px;
					border-bottom: 0;
					&:before {
						content: '';
						position: absolute;
						left: -21px;
						top: 0;
						background-image: url(../img/slider-nav.png);
						display: block;
						width: 21px;
						height: 119px; }
					&:after {
						content: '01';
						position: absolute;
						left: 0;
						right: 0;
						margin: 0 auto;
						text-align: center;
						opacity: 0.15;
						color: #eebe98;
						font-family: "Open Sans";
						font-size: 120px;
						font-weight: 700;
						line-height: 20px;
						display: block;
						top: 50%;
						transform: translateY(-50%); } } } }
		.item {
			.item-contant {
				position: relative;
				.text {
					position: absolute;
					top: 40px;
					left: 65px; }
				.title {
					color: #232323;
					font-family: "Open Sans";
					font-size: 55px;
					font-weight: 600;
					line-height: 55px;
					max-width: 495px;
					&.title2 {
						line-height: 40px;
						font-size: 50px;
						padding-top: 5px;
						margin-bottom: 34px;
						strong {
							font-size: 65px;
							line-height: 69px; } }
					&.title3 {
						margin-bottom: 20px; }
					strong {
						font-size: 75px;
						font-weight: 700;
						line-height: 71px;
						display: block; } }
				.text-descr {
					color: #666;
					font-family: "Open Sans";
					font-size: 17px;
					font-weight: 600;
					line-height: 20px;
					padding-top: 15px;
					padding-left: 7px; }
				.btn-buy {
					width: 110px;
					height: 40px;
					background-color: #ae2f3c;
					display: inline-block;
					color: white;
					font-family: "Open Sans";
					font-size: 11px;
					font-weight: 700;
					line-height: 24px;
					text-transform: uppercase;
					-webkit-transition: all .25s;
					-moz-transition: all .25s;
					-ms-transition: all .25s;
					-o-transition: all .25s;
					transition: all .25s;
					display: inline-flex;
					align-items: center; /* cross axis */;
					line-height: 1; /* reset */;
					justify-content: center;
					margin-top: 19px;
					margin-left: 5px;
					&:hover {
						background-color: #981e2b; } } } } } }

.catalog {
	padding-top: 16px;
	padding-bottom: 26px;
	h2 {
		color: #232323;
		font-family: "Open Sans";
		font-size: 60px;
		font-weight: 600;
		line-height: 71px;
		text-transform: uppercase;
		display: inline-block;
		vertical-align: top;
		padding-left: 19px; }
	h3 {
		color: #232323;
		font-family: "Open Sans";
		font-size: 25px;
		font-weight: 600;
		line-height: 1.2;
		text-transform: uppercase;
		display: inline-block;
		vertical-align: top;
		padding-left: 7px;
		padding-top: 17px; }
	.controls {
		display: inline-block;
		vertical-align: top;
		padding-top: 16px;
		padding-left: 20px;
		padding-bottom: 5px;
		li {
			display: inline-block;
			vertical-align: top;
			margin-right: 16px;
			&:last-child {
				margin-right: 0; }
			a {
				color: #232323;
				font-family: "Open Sans";
				font-size: 18px;
				font-weight: 600;
				line-height: 71px;
				display: block;
				&:hover, &.mixitup-control-active {
					color: #be926f;
					text-decoration: underline; } } } }
	.mix-container {
		border-top: 1px solid #e6e6e6;
		height: 378px;
		overflow: hidden; }
	.item-wrap {
		text-align: center;
		border-right: 1px solid #e6e6e6;
		border-bottom: 1px solid #e6e6e6;
		&:hover {
			.product-name {
				color: #be926f; }
			.product-buy {
				opacity: 1; } }
		&:nth-child(4n+4) {
			border-right: 0; }
		.item {
			padding-bottom: 22px;
			position: relative; }
		.product-name {
			color: #232323;
			font-family: "Open Sans";
			font-size: 17px;
			font-weight: 700;
			line-height: 22px;
			padding-top: 24px;
			text-align: center;
			padding-left: 15px;
			padding-right: 15px; }
		.product {
			color: #999;
			font-family: "Open Sans";
			font-size: 12px;
			font-weight: 600;
			line-height: 24px;
			padding-top: 8px; }
		.img-wrap {
			width: 210px;
			height: 210px;
			background-color: #e7e7e7;
			overflow: hidden;
			position: relative;
			margin: 0 auto;
			margin-top: 10px;
			img {
				position: absolute;
				left: 0;
				top: 50%;
				right: 0;
				margin: 0 auto;
				transform: translateY(-50%);
				display: block; } }

		.price {
			color: #be926f;
			font-family: "Open Sans";
			font-size: 20px;
			font-weight: 600;
			line-height: 24px;
			padding-top: 10px; }
		.product-buy {
			background-color: rgba(255,255,255,0.9);
			position: absolute;
			left: 0;
			right: 0;
			top: 110px;
			height: 210px;
			width: 100%;
			text-align: center;
			opacity: 0;
			-webkit-transition: all 0.37s cubic-bezier(0.300, 0.100, 0.580, 1.000);
			-moz-transition: all 0.37s cubic-bezier(0.300, 0.100, 0.580, 1.000);
			-o-transition: all 0.37s cubic-bezier(0.300, 0.100, 0.580, 1.000);
			-ms-transition: all 0.37s cubic-bezier(0.300, 0.100, 0.580, 1.000);
			transition: all 0.37s cubic-bezier(0.300, 0.100, 0.580, 1.000);
			.btn-buy {
				display: inline-block;
				width: 130px;
				height: 40px;
				background-color: #ae2f3c;
				color: white;
				font-family: "Open Sans";
				font-size: 13px;
				font-weight: 700;
				line-height: 24px;
				display: inline-flex;
				align-items: center; /* cross axis */;
				line-height: 1; /* reset */;
				justify-content: center;
				-webkit-transition: all .25s;
				-moz-transition: all .25s;
				-ms-transition: all .25s;
				-o-transition: all .25s;
				transition: all .25s;
				margin: 0 auto;
				margin-top: 64px;
				background-image: url(../img/arrow.png);
				background-repeat: no-repeat;
				background-position: 75% center;
				padding-right: 13px;
				&:hover {
					background-color: #981e2b; } }
			.more-info {
				color: #999;
				font-family: "Open Sans";
				font-size: 13px;
				font-weight: 700;
				line-height: 24px;
				text-decoration: underline;
				-webkit-transition: all .25s;
				-moz-transition: all .25s;
				-ms-transition: all .25s;
				-o-transition: all .25s;
				transition: all .25s;
				margin-top: 19px;
				display: inline-block;

				&:hover {
					text-decoration: none; } } } }
	.show-more, .show-less {
		text-align: center;
		color: #232323;
		font-family: "Open Sans";
		font-size: 18px;
		font-weight: 600;
		line-height: 60px;
		display: block;
		border-top: 1px solid #e6e6e6;
		margin-top: -1px; }
	.show-less {
		display: none; } }

.slider2 {
	.slider-wrap {
		position: relative;
		h2 {
			position: absolute;
			left: 0;
			right: 0;
			margin: 0 auto;
			color: white;
			font-family: "Open Sans";
			font-size: 31px;
			font-weight: 600;
			line-height: 20px;
			background-color: #be926f;
			z-index: 100;
			text-align: center;
			max-width: 456px;
			top: 24px;
			height: 65px;
			display: inline-flex;
			align-items: center; /* cross axis */;
			line-height: 1; /* reset */;
			justify-content: center; }
		.bxslider2 {
			position: relative;
			z-index: 0; } }
	.bx-wrapper .bx-pager {
		padding-top: 0; }
	.bx-wrapper .bx-pager, .bx-wrapper .bx-controls-auto {
		bottom: 10px; }
	.bx-wrapper .bx-pager.bx-default-pager a {
		width: 13px;
		height: 13px;
		background-color: #fbfbfb;
		border: 1px solid #fbfbfb;
		border-radius: 50%;
		&.active {
			background-color: #be926f; } } }

.catalog-link {
	.text-wrap {
		h2 {
			color: #232323;
			font-family: "Open Sans";
			line-height: 60px;
			font-size: 60px;
			font-weight: 600;
			text-transform: uppercase;
			strong {
				font-size: 75px;
				font-weight: 700; } }
		.text-descr {
			color: #be926f;
			font-family: "Open Sans";
			font-size: 31px;
			font-weight: 600;
			line-height: 37px;
			padding-top: 5px; }
		.text {
			color: #666;
			font-family: "Open Sans";
			font-size: 14px;
			font-weight: 400;
			line-height: 18px;
			padding-top: 17px;
			webkit-hyphens: auto;
			-moz-hyphens: auto;
			-ms-hyphens: auto;
			hyphens: auto;
			.br {
				display: block;
				padding-top: 6px; } }
		.btn-link {
			width: 191px;
			height: 40px;
			background-color: #ae2f3c;
			color: white;
			font-family: "Open Sans";
			font-size: 11px;
			font-weight: 700;
			line-height: 24px;
			text-transform: uppercase;
			-webkit-transition: all .25s;
			-moz-transition: all .25s;
			-ms-transition: all .25s;
			-o-transition: all .25s;
			transition: all .25s;
			display: inline-flex;
			align-items: center; /* cross axis */;
			line-height: 1; /* reset */;
			justify-content: center;
			margin-top: 21px;
			letter-spacing: 2px;
			&:hover {
				background-color: #981e2b; } } } }

.money-box-info {
	padding-top: 70px;
	position: relative;
	z-index: 0;
	.item-text {
		padding-right: 0;
		.text-wrap {
			margin-right: -9px; } }
	.item-img {
		padding-right: 0; }
	.img-wrap {
		img {
			display: block;
			margin-top: 96px; } } }

.spectacles-info {
	background-image: url(../img/spect-bg.png);
	height: 881px;
	background-size: cover;
	margin-top: -170px;
	position: relative;
	z-index: 2;
	.item-img {
		padding-right: 0;
		padding-left: 0; }
	.img-wrap {
		img {
			display: block;
			margin-top: 126px; } }
	.text-wrap {
		text-align: right;
		padding-top: 50px;
		h2 {
			margin-left: -361px;
			line-height: 65px; }
		.text-descr {
			padding-top: 0; }
		.text {
			margin-left: -14px;
			text-align: left;
			padding-top: 15px; }
		.btn-link {
			margin-top: 18px; } } }

.decor-info {
	position: relative;
	z-index: 3;
	background-color: #fff;
	background-image: url(../img/catalog-img3.png);
	background-position: 106% bottom;
	background-repeat: no-repeat;
	min-height: 591px;
	&:before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		background-image: url(../img/decor-bg.png);
		height: 169px;
		top: -169px; }
	.text-wrap {
		margin-top: -3px;
		h2 {
			margin-right: -198px;
			line-height: 65px; }
		.text-descr {
			padding-top: 0; }
		.text {
			padding-top: 14px;
			margin-right: -20px; }
		.btn-link {
			margin-top: 17px; } }
	.img-wrap {
		display: none; } }

.partners {
	background-color: #e7e7e7;
	min-height: 350px;
	background-image: url(../img/decor.png);
	background-position: center 0;
	padding-bottom: 30px;
	text-align: center;
	h2 {
		color: #be926f;
		font-family: "Open Sans";
		font-size: 40px;
		font-weight: 700;
		line-height: 60px;
		text-transform: uppercase;
		position: relative;
		padding-top: 44px;
		&:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: -16px;
			display: block;
			width: 20px;
			height: 2px;
			background-color: #be926f;
			margin: 0 auto; } }
	.text-descr {
		color: #232323;
		font-family: "Open Sans";
		font-size: 17px;
		font-weight: 700;
		line-height: 28px;
		max-width: 742px;
		margin: 0 auto;
		padding-top: 26px; }
	.btn-callback {
		display: inline-block;
		width: 110px;
		height: 40px;
		border: 2px solid #be926f;
		display: inline-flex;
		align-items: center; /* cross axis */;
		line-height: 1; /* reset */;
		justify-content: center;
		color: #232323;
		font-family: "Open Sans";
		font-size: 11px;
		font-weight: 700;
		margin-top: 43px;
		line-height: 24px;
		text-transform: uppercase;
		-webkit-transition: all .25s;
		-moz-transition: all .25s;
		-ms-transition: all .25s;
		-o-transition: all .25s;
		transition: all .25s;
		&:hover {
			color: #fff;
			background-color: #be926f; } } }

.about {
	padding-bottom: 30px;
	.logo {
		padding-top: 33px;
		img {
			margin: 0 auto; } }
	.text {
		padding-top: 34px;
		p {
			color: #666;
			font-family: "Open Sans";
			font-size: 14px;
			font-weight: 400;
			line-height: 18px;
			padding-bottom: 5px; } } }

.contacts {
	text-align: center;
	padding-top: 13px;
	.contacts-line {
		max-width: 1120px;
		margin: 0 auto;
		padding-bottom: 33px; }
	.item-wrap {
		.img-wrap {
			height: 60px;
			width: 100%;
			background-position: center center;
			background-repeat: no-repeat;
			&.img1 {
				background-image: url(../img/icon-phone.png); }
			&.img2 {
				background-image: url(../img/icon-place.png); }
			&.img3 {
				background-image: url(../img/icon-mail.png); } }
		.title {
			color: black;
			font-family: "Open Sans";
			font-size: 20px;
			font-weight: 400;
			line-height: 24px;
			padding-top: 15px; }
		.text {
			color: #ae2f3c;
			font-family: "Open Sans";
			font-size: 14px;
			font-weight: 400;
			line-height: 30px;
			padding-top: 24px; }
		.contact-link {
			font-size: 24px;
			line-height: 34px;
			color: #ae2f3c;
			display: block;
			padding-top: 20px; } } }

.map {
	height: 470px; }

footer {
	min-height: 70px;
	.copy {
		color: #ccc;
		font-family: "Open Sans";
		font-size: 13px;
		font-weight: 400;
		line-height: 24px;
		span {
			font-weight: 600;
			line-height: 65px;
			text-transform: uppercase; } }
	.menu3 {
		padding-top: 22px;
		margin-left: 114px;
		li {
			display: inline-block;
			vertical-align: top;
			margin-right: 10px;
			&:last-child {
				margin-right: 0; }
			a {
				color: #999;
				font-family: "Open Sans";
				font-size: 13px;
				font-weight: 400;
				line-height: 24px;
				display: block;
				-webkit-transition: all .25s;
				-moz-transition: all .25s;
				-ms-transition: all .25s;
				-o-transition: all .25s;
				transition: all .25s;
				&:hover {
					color: #ae2f3c; } } } }
	.soc-icons {
		padding-top: 15px;
		text-align: right;
		margin-right: 14px;
		li {
			display: inline-block;
			margin-right: 4px;
			vertical-align: top;
			&:last-child {
				margin-right: 0; }
			a {
				display: block;
				width: 40px;
				height: 40px;
				background-repeat: no-repeat;
				background-position: center center;
				-webkit-transition: all .25s;
				-moz-transition: all .25s;
				-ms-transition: all .25s;
				-o-transition: all .25s;
				transition: all .25s;
				&:hover {
					&.vk {
						background-image: url(../img/icon-vk.png); }
					&.ok {
						background-image: url(../img/icon-ok.png); }
					&.fb {
						background-image: url(../img/icon-fb.png); } }
				&.vk {
					background-image: url(../img/icon-vk-grey.png); }
				&.ok {
					background-image: url(../img/icon-ok-grey.png); }
				&.fb {
					background-image: url(../img/icon-fb-grey.png); } } } } }

.popup1 {
	max-width: 759px;
	min-height: 558px;
	background-color: #e7e7e7;
	box-shadow: 1px 1.7px 10px rgba(0, 0, 0, 0.75);
	background-image: url(../img/popup1-bg.png);
	background-position: center bottom;
	margin: 0 auto;
	position: relative;
	.mfp-close {
		background-image: url(../img/close.png);
		width: 37px;
		height: 37px;
		display: block;
		position: absolute;
		right: 15px;
		top: 20px;
		cursor: pointer; }
	h2 {
		text-align: center;
		color: #232323;
		font-family: "Open Sans";
		font-size: 35px;
		font-weight: 700;
		text-transform: uppercase;
		padding-top: 15px; }
	.form-wrap {
		max-width: 670px;
		margin: 0 auto;
		padding-top: 2px; }
	.text-label {
		color: #202d33;
		font-family: "Open Sans";
		font-size: 13px;
		font-weight: 400;
		line-height: 28px;
		padding-left: 10px;
		display: block; }
	input {
		width: 100%;
		height: 38px;
		background-color: white;
		border: 1px solid #be926f;
		border-radius: 1px;
		display: block;
		max-width: 323px;
		opacity: 1;
		color: rgba(32,45,51,0.5);
		font-family: "Open Sans";
		font-size: 13px;
		font-style: italic;
		line-height: 31px;
		margin-top: -3px;
		margin-left: 1px;
		padding-left: 7px; }
	input::-webkit-input-placeholder /* Chrome/Opera/Safari */ {
		opacity: 1;
		color: rgba(32,45,51,0.5); }
	input::-moz-placeholder /* Firefox 19+ */ {
		opacity: 1;
		color: rgba(32,45,51,0.5); }
	input:-ms-input-placeholder /* IE 10+ */ {
		opacity: 1;
		color: rgba(32,45,51,0.5); }
	input:-moz-placeholder /* Firefox 18- */ {
		opacity: 1;
		color: rgba(32,45,51,0.5); }
	textarea::-webkit-input-placeholder /* Chrome/Opera/Safari */ {
		opacity: 1;
		color: rgba(32,45,51,0.5); }
	textarea::-moz-placeholder /* Firefox 19+ */ {
		opacity: 1;
		color: rgba(32,45,51,0.5); }
	textarea:-ms-input-placeholder /* IE 10+ */ {
		opacity: 1;
		color: rgba(32,45,51,0.5); }
	textarea:-moz-placeholder /* Firefox 18- */ {
		opacity: 1;
		color: rgba(32,45,51,0.5); }
	.contact-info {
		font-family: "Open Sans";
		font-size: 15px;
		line-height: 18px;
		color: #232323;
		font-weight: 400;
		padding-top: 10px;
		span {
			color: #ae2f3c;
			font-weight: 700; } }
	.btn {
		display: block;
		max-width: 323px;
		width: 100%;
		height: 46px;
		background-color: #ae2f3c;
		border: none;
		cursor: pointer;
		border-radius: 1px;
		-webkit-transition: all .25s;
		-moz-transition: all .25s;
		-ms-transition: all .25s;
		-o-transition: all .25s;
		transition: all .25s;
		display: inline-flex;
		align-items: center; /* cross axis */;
		line-height: 1; /* reset */;
		justify-content: center;
		margin-top: 15px;
		color: white;
		font-family: "Open Sans";
		font-size: 12px;
		font-weight: 600;
		line-height: 25px;
		letter-spacing: 2px;
		&:hover {
			background-color: #981e2b; } }
	textarea {
		max-width: 323px;
		width: 100%;
		height: 163px;
		background-color: white;
		border: 1px solid #be926f;
		border-radius: 1px;
		display: block;
		margin-top: -3px;
		opacity: 1;
		color: rgba(32,45,51,0.5);
		font-family: "Open Sans";
		font-size: 13px;
		font-style: italic;
		line-height: 31px;
		padding-left: 7px; }
	.text-info {
		color: rgba(35,35,35,0.8);
		font-family: "Open Sans";
		font-size: 10px;
		font-weight: 700;
		line-height: 18px;
		text-transform: uppercase;
		text-align: center; } }

.popup2 {
	max-width: 1111px;
	min-height: 703px;
	background-color: white;
	box-shadow: 1px 1.7px 10px rgba(0, 0, 0, 0.75);
	margin: 0 auto;
	position: relative;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 44px;
	padding-bottom: 40px;
	.contant-wrap {
		max-width: 1009px;
		margin: 0 auto; }
	.slider-wrap {
		margin-right: -10px;
		.slider-bx {
			.img-wrap {
				display: block;
				border: 1px solid rgba(35,35,35,0.5);
				width: 100%;
				height: 477px;
				overflow: hidden; } }
		.pager-wrap {
			margin-top: 22px;
			text-align: center;
			a {
				display: inline-block;
				vertical-align: top;
				max-width: 146px;
				margin-right: 37px;
				margin-bottom: 37px; }
			.img-wrap {
				max-width: 146px;
				height: 120px;
				width: 123%;
				border: 1px solid rgba(35,35,35,0.5);
				display: block;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%; } } } }
	.mfp-close {
		text-indent: 0;
		position: relative;
		opacity: 1;
		font-family: "Open Sans";
		color: #6b6b6b;
		font-size: 14px;
		font-weight: 700;
		line-height: 12px;
		margin-top: 11px;
		display: block;
		max-width: 300px;
		width: 100%;
		text-align: left;
		padding-left: 0;
		padding-bottom: 0;
		height: auto;
		cursor: pointer;
		span {
			color: #ae2f3c;
			font-size: 14px;
			font-weight: 700;
			line-height: 12px; } }
	.text-wrap {
		padding-left: 10px;
		margin-right: -5px; }
	h2 {
		font-family: "Open Sans";
		color: #232323;
		font-size: 24px;
		font-weight: 700;
		padding-top: 17px; }
	.text-art {
		font-family: "Open Sans";
		color: #6b6b6b;
		font-size: 18px;
		font-weight: 600; }
	.list {
		padding-top: 22px;
		li {
			font-family: "Open Sans";
			font-size: 14px;
			font-weight: 700;
			line-height: 21px;
			color: #232323;
			span {
				color: #be926f; } } }
	.text-descr {
		font-family: "Open Sans";
		font-size: 14px;
		line-height: 16px;
		color: #555;
		font-weight: 400;
		padding-top: 28px;
		strong {
			color: #ae2f3c;
			font-weight: 600; }
		.br {
			display: block;
			padding-bottom: 16px; } }
	.form-wrap {
		.line {
			padding-top: 27px; }
		.block {
			display: inline-block;
			vertical-align: top;
			margin-right: 16px;
			strong {
				color: #232323;
				font-family: "Open Sans";
				font-size: 14px;
				font-weight: 700;
				line-height: 24px;
				text-transform: uppercase;
				display: block;
				padding-bottom: 2px; } } }
	.select2-container--default .select2-selection--single {
		width: 168px;
		height: 35px;
		border: 1px solid #e1e1e1;
		border-radius: 0;
		color: rgba(107,107,107,0.6);
		font-family: "Open Sans";
		font-size: 13px;
		font-weight: 600;
		line-height: 24px;
		padding-left: 5px; }
	.select2-container--default .select2-selection--single .select2-selection__arrow b {
		display: none; }
	.select2-container--default .select2-selection--single .select2-selection__arrow {
		background-image: url(../img/arrow2.png);
		width: 5px;
		height: 7px;
		top: 50%;
		transform: translateY(-50%);
		right: 13px; }
	.select2-container--default .select2-selection--single .select2-selection__rendered {
		display: inline-flex;
		align-items: center; /* cross axis */;
		line-height: 1; /* reset */;
		justify-content: center;
		height: 35px; }
	.select2-container--default .select2-selection--single .select2-selection__clear {
		float: none;
		position: absolute;
		right: 30px;
		top: 50%;
		transform: translateY(-50%); }
	.select2-container--default .select2-selection--single .select2-selection__rendered {
		color: rgba(107,107,107,0.6); }

	.wrap-counter {
		width: 70px;
		height: 35px;
		border: 1px solid #e1e1e1;
		overflow: hidden;
		input {
			display: inline-block;
			border: none;
			border-right: 1px solid #e1e1e1;
			width: 35px;
			height: 35px;
			vertical-align: top;
			font-family: "Open Sans";
			font-size: 13px;
			font-weight: 600;
			line-height: 24px;
			text-align: center;
			color: rgba(107,107,107,0.6); }
		.block-nav {
			display: inline-block;
			vertical-align: top;
			height: 35px;
			width: 32px;
			float: right;

			.plus {
				background-image: url(../img/arrows.png);
				background-position: center 7px;
				width: 100%;
				height: 17px;
				margin: 0 auto;
				display: block;
				cursor: pointer;
				background-repeat: no-repeat; }
			.line1 {
				height: 1px;
				background-color: #e1e1e1;
				width: 100%; }
			.minus {
				background-image: url(../img/arrows.png);
				background-position: center -12px;
				width: 100%;
				height: 17px;
				margin: 0 auto;
				display: block;
				cursor: pointer;
				background-repeat: no-repeat; } } }
	.price {
		color: #be926f;
		font-family: "Open Sans";
		font-size: 24px;
		font-weight: 700;
		line-height: 48px;
		text-transform: uppercase;
		padding-top: 20px;
		padding-left: 47px; }
	.form-btn-buy {
		width: 130px;
		height: 40px;
		background-color: #ae2f3c;
		display: block;
		color: white;
		font-family: "Open Sans";
		font-size: 13px;
		font-weight: 700;
		line-height: 24px;
		background-image: url(../img/arrow3.png);
		background-repeat: no-repeat;
		background-position: 75% center;
		-webkit-transition: all .25s;
		-moz-transition: all .25s;
		-ms-transition: all .25s;
		-o-transition: all .25s;
		transition: all .25s;
		display: inline-flex;
		align-items: center; /* cross axis */;
		line-height: 1; /* reset */;
		justify-content: center;
		margin-top: 21px;
		padding-right: 16px;
		&:hover {
			background-color: #981e2b; } }
	.input-wrap {
		display: none;
		padding-top: 8px;
		.left-block {
			display: inline-block;
			vertical-align: top;
			width: 66%;
			float: left; }
		.right-block {
			display: inline-block;
			vertical-align: top;
			width: 20%;
			float: left; }
		.text-label {
			color: #202d33;
			font-family: "Open Sans";
			font-size: 13px;
			font-weight: 400;
			line-height: 28px;
			padding-left: 10px;
			display: block; }
		input {
			width: 100%;
			height: 38px;
			background-color: white;
			border: 1px solid #be926f;
			border-radius: 1px;
			display: block;
			max-width: 323px;
			opacity: 1;
			color: rgba(32,45,51,0.5);
			font-family: "Open Sans";
			font-size: 13px;
			font-style: italic;
			line-height: 31px;
			margin-top: -3px;
			margin-left: 1px;
			padding-left: 7px; }
		input::-webkit-input-placeholder /* Chrome/Opera/Safari */ {
			opacity: 1;
			color: rgba(32,45,51,0.5); }
		input::-moz-placeholder /* Firefox 19+ */ {
			opacity: 1;
			color: rgba(32,45,51,0.5); }
		input:-ms-input-placeholder /* IE 10+ */ {
			opacity: 1;
			color: rgba(32,45,51,0.5); }
		input:-moz-placeholder /* Firefox 18- */ {
			opacity: 1;
			color: rgba(32,45,51,0.5); } }
	.btn {
		width: 130px;
		height: 82px;
		background-color: #ae2f3c;
		display: block;
		color: white;
		font-family: "Open Sans";
		font-size: 13px;
		font-weight: 700;
		line-height: 17px;
		-webkit-transition: all .25s;
		-moz-transition: all .25s;
		-ms-transition: all .25s;
		-o-transition: all .25s;
		transition: all .25s;
		margin-top: 21px;
		padding-right: 16px;
		border: none;
		margin-top: 107px;
		margin-left: 41px;
		position: relative;
		span {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			right: 0;
			margin: 0 auto; }
		&:hover {
			background-color: #981e2b; } } }

.popup3 {
	max-width: 557px;
	min-height: 188px;
	background-color: white;
	box-shadow: 1px 1.7px 10px rgba(0, 0, 0, 0.75);
	background-image: url(../img/thanks-bg.png);
	background-position: center 0;
	text-align: center;
	margin: 0 auto;
	h2 {
		font-family: "Open Sans";
		text-transform: uppercase;
		color: #ae2f3c;
		font-size: 52px;
		font-weight: 700;
		padding-top: 23px; }
	.text-descr {
		font-family: "Open Sans";
		text-transform: uppercase;
		color: #232323;
		font-size: 35px;
		font-weight: 700;
		margin-top: -10px;
		span {
			color: #ae2f3c;
			font-size: 19px;
			font-weight: 600;
			display: block;
			margin-top: -10px; } } }


